@import '../../styles/common/variables.scss';
@import '../../styles/common/mixins.scss';

.labelOverride {
    label {
        font-weight: 400;
    }
}

.modal {
    @media (min-width: $screen-lg) {
        max-height: 80vh;

    }
    display: flex;
    flex-direction: column;
}

.modalContentContainer {
    flex: 1;
    overflow-y: auto;
    padding: 1rem; /* Optional: Add padding if needed */
}

.modalContent {
    max-height: 100%;
    overflow-y: auto;
}

.noPaddingOnBlocks {
   > div {
        padding-left: 0;
        padding-right: 0;
    }
}