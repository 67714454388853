@import '../../styles/common/variables.scss';

.image {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    color: transparent;
}

.cardItem {
    text-decoration: none;
    background-color: $primary-green-50;

    &:hover {
        background-color: $primary-green-100;
    }

    &:focus {
        outline: 2px solid $interactive-blue;
        outline-offset: 2px;
    }

    &:active {
        background-color: $primary-green-200;
    }
}