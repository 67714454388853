@import '../../styles/common/mixins.scss';

.accordionExpandIcon {
    padding: 4px;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid #ffffff;

}

.footerAccordion {
    display: grid;
    grid-template-columns: 1fr;
}

.contentContainer {
    grid-column: 1 / -1;
}

.link {
    @include footer-link;
}

.contentContainer {
    overflow: hidden;
    transition: max-height 0.3s ease;
    max-height: 0;
}

.expanded {
    max-height: 9000px; /* Set a large enough value to accommodate the content */
}