@import '../../styles/common/variables.scss';
@import '../../styles/common/mixins.scss';


.unitInformation {
    @include font-standard;
    text-align: left;
    margin-bottom: 0;

    @media (max-width: $screen-lg) {
        font-size: 14px;
        display: inline-block;
        width: 100%;
        text-align: right;
        line-height: normal;
    }
}

.radioButton {
    label {
        margin-bottom: 0px;
    }
}

.priceContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

// .ordPrice {
//     // width: 150px;
// }

.specialPriceLabelContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;

    @media (min-width: $screen-lg) {
        justify-content: flex-end;
    }

    + .priceContainer {
        @media (max-width: $screen-sm) {
            max-width: 60px;
        }
    }
}

.specialPriceLabel {
    background-color: $secondary-orange-400;
    border-radius: 9999px;
    padding: 5px 20px;
    color: $accent-white;
    white-space: nowrap;
    height: 28px;
    margin-bottom: 0;
    text-align: center;

    @media (max-width: $screen-md) {
        padding: 5px 10px;
    }
}

.discountedPrice {
    text-decoration: line-through;
}