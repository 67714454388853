@import '../../styles/common/mixins.scss';
@import '../../styles/common/variables.scss';

.columnContainer:not(:nth-last-child(-n+2)) {
    border-right: 1px solid rgb(var(--color-white));
}

.copyrightContainer {
    height: 40px;
    
    span {
        &:not(:last-child)::after {
            content: '·';
            margin: 0 0.5rem;
        }

        a {
            display: inline-block;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.mobileAccordion {
    color: $accent-white;

    >div {
        color: $accent-white;
        padding: 0;

        h3 {
            color: $accent-white;
            padding: 0;
            margin: 0;
        }

        img {
            padding: 4px;
            width: 24px;
            height: 24px;
            border-radius: 4px;
            border: 1px solid #ffffff;
        }
    }

    div:nth-child(2) {
        >div {
            padding-right: 0;
            padding-left: 0;
        }
    }
}