@import "../../styles/common/variables.scss";
@import "../../styles/common/mixins.scss";

.searchFunctionWrapper {
    &:hover {
        .searchBoxContainer {
            input[type="text"] {
                border-color: $primary-green-400;
            }
        }

        .searchResultContainer {
            border-color: $primary-green-400;
        }
    }

    .searchBoxContainer {
        height: 60px;
        position: relative;

        input[type="text"] {
            border-radius: 9999px;
            width: 100%;
            height: 100%;
            padding-left: 2rem;

            &::placeholder {
                color: $secondary-black-400;
            }
        }

        div {
            position: absolute;
            right: 1rem;
            display: flex;
            align-items: center;
            top: 5px;
            right: 5px;

            a.iconLink {
                margin-right: 0.25rem;
                padding: 0.75rem;
            }

            button {
                position: relative;
            }
        }
    }

    .searchResultContainer {
        position: absolute;
        top: 59px;
        left: 29px;
        background: $accent-white;
        border-radius: 0 0 0.5rem 0.5rem;
        max-width: 310px;
        width: 310px;
        max-height: 300px;
        border-bottom-left-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;
        z-index: 10;
        padding: 1rem;
        overflow: hidden;
        box-sizing: border-box;
        border: 1px solid $border-grey;
        border-top: none;

        .searchResults {
            max-height: calc(300px - 2rem);
            overflow-y: auto;
            padding: 0.5rem;
            box-sizing: border-box;

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $primary-green-400;
                border-radius: 8px;
            }

            &::-webkit-scrollbar-track {
                background-color: $primary-green-200;
            }

            .cityHeader {
                display: block;
                padding: 0.5rem;
            }

            ul {
                list-style: none;
                padding: 0 0 0 1rem;
                margin: 0;

                li {
                    padding: 0.5rem;
                    border-bottom: 1px solid #eee;

                    a .facilityAddress {
                        text-decoration: none !important; // Remove underline for facilityAddress
                    }
                }
            }
        }
    }
}



.facilityLink {
    font-family: "Quicksand Variable", sans-serif;
    line-height: 16px;
    font-weight: bold;
    font-size: 14px;
    padding-right: 37px;
    padding-left: 5px;
    position: relative;
    min-height: 32px;
    color: $secondary-black-400;
    display: flex;
    align-items: center;
    padding: 5px 5px;
    width: 100%;

    &::before {
        background-color: $primary-green-400 !important;
        background-image: url(/assets/img/icons/chevron.svg);
        left: auto;
        right: 5px;
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        border-radius: 4px;
        background-size: 16px 16px;
        background-position: center;
        background-repeat: no-repeat;
    }

    &::before {
        background-color: $primary-green-400 !important;
        background-image: url('/assets/img/icons/chevron.svg');
    }

    &:hover::before {
        background-color: $primary-green-300 !important;
    }

    &:focus::before {
        background-color: $primary-green-400 !important;
    }

    &:active::before {
        background-color: $primary-green-500 !important;
    }

    &:disabled::before,
    &[aria-disabled="true"]::before {
        opacity: 0.6;
    }

    .facilityName {
        text-decoration: underline;

    }

    .facilityAddress {
        @include font-standard;
        font-size: 12px;
        text-decoration: none !important;
        line-height: unset;
        font-weight: normal;
    }

}

.showAllFacilitiesLink {
    color: $secondary-black-400;
    font-weight: normal;

    @media (max-width: $screen-md) {
        justify-content: center;
    }
}

.diagonalBanner {
    position: absolute;
    top: 10%;
    left: -30%;
    width: 200%;
    background-color: $secondary-orange-400;
    color: $accent-white;
    text-align: center;
    transform: rotate(20deg);
    transform-origin: center;
    z-index: 10;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    line-height: normal;

    @media (max-width: $screen-md) {
        transform: rotate(45deg);
        top: 15%;
        left: 12%;
        width: 125%;
    }
}

.searchButton {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
        content: '';
        display: none;
        background-image: url('/assets/img/icons/icon_magnifying-glass_white.svg');
        background-size: contain;
        background-repeat: no-repeat;
        width: 16px;
        height: 16px;
    }

    @media (max-width: $screen-md) {
        &::before {
            display: block;
        }

        span {
            display: none;
        }

        padding: 17px;
    }
}

.checkList {
    li {
        @include font-standard;
        font-weight:bold;
    }
}