.link {
    width:40px;
    height: 40px;
    border: 1px solid #fff;
    border-radius: 50%;
    font-size: unset;
    line-height: unset;
    padding: unset;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    width: 16px;
    height: 16px;
    max-width: 16px;
    max-height: 16px;
}