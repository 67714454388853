@import '../../styles/common/variables.scss';

.unitAccordion {
    border: 1px solid $secondary-black-200;
}


.options {
    max-height: 200px;
    overflow-y: auto;
    z-index: 11;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $primary-green-400;
        border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
        background-color: $primary-green-200;
    }
}