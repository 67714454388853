/* modal.module.scss */
@import '../../styles/common/variables.scss';
@import '../../styles/common/mixins.scss';

@mixin backdrop-common {
    background: rgba($primary-green-300, 0.4); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.noBackdrop {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.backdrop {
    @include backdrop-common;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
}

.backdropFullWindow {
    @include backdrop-common;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.loader {
    border: 10px solid $accent-white;
    border-radius: 50%;
    border-top: 10px solid $primary-green-400;
    width: 100px;
    height: 100px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    z-index: 1000;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5), /* Outer shadow */
    inset 0 0 5px rgba(0, 0, 0, 0.5); /* Inner shadow */
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}