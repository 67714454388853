@import '../../styles/common/mixins.scss';
@import '../../styles/common/variables.scss';

.columnHeader {
    @include footer-header;
}

.link {
    @include footer-link;
}

.mobileAccordion {
    color: $accent-white;

    >div {
        color: $accent-white;
        padding: 0;

        h3 {
            color: $accent-white;
            padding: 0;
            margin: 0;
        }

        img {
            padding: 4px;
            width: 24px;
            height: 24px;
            border-radius: 4px;
            border: 1px solid #ffffff;
        }
    }

    div:nth-child(2) {
        >div {
            padding-right: 0;
            padding-left: 0;
        }
    }
}